<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Kategorien</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/vehicle_categories/new" class="button button-orange button-small">Kategorie hinzufügen ›</router-link></p>
            </div>
          </header>

          <div class="admin-body">

            <div class="search-wrap">
              <input v-model="search_phrase" v-on:input="search_handler" type="text" class="search-field" placeholder="Suchbegriff eingeben...">
            </div>

            <div v-if="loading">
              <div class="loading-wrap">
                <div class="loading-spinner"></div>
              </div>
            </div>
            <div v-else>

              <div v-if="search_loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>

                <div v-if="vehicle_categories.length > 0">

                  <table class="table">
                    <thead>
                      <tr>
                        <th width="5%"></th>
                        <th width="10%"></th>
                        <th width="40%">Name</th>
                        <th width="25%" style="text-align:center">Features</th>
                        <th width="10%" style="text-align:center">Fahrzeuge</th>
                        <th width="10%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="category in vehicle_categories" v-bind:key="category.id">
                        <td>
                          <span v-if="category.status == 'enabled'" class="status status-green"></span>
                          <span v-if="category.status == 'disabled'" class="status status-red"></span>
                        </td>
                        <td v-if="category.image">
                          <img :src="category.image" height="50">
                        </td>
                        <td v-else>
                          <div style="width:50px;height:50px;background:#f1f1f1"></div>
                        </td>
                        <td>
                          <router-link :to="'/admin/vehicle_categories/'+category.id">
                            {{ category.name }}
                          </router-link>
                        </td>
                        <td style="text-align:center">
                          <div class="vc-w">
                            <span class="material-icons">people</span>
                            <span>{{ category.seats }}</span>
                          </div>
                          <div class="vc-w">
                            <span class="material-icons">sensor_door</span>
                            <span>{{ category.doors }}</span>
                          </div>
                          <div class="vc-w">
                            <span class="material-icons">work</span>
                            <span>{{ category.luggage }}</span>
                          </div>
                          <div class="vc-w">
                            <span class="material-icons">directions_car</span>
                            <span v-if="category.drive == 'manual'">M</span>
                            <span v-if="category.drive == 'automatic'">A</span>
                          </div>
                        </td>
                        <td style="text-align:center">
                          {{ category.vehicle_count }}
                        </td>
                        <td>
                          <router-link :to="'/admin/vehicle_categories/'+category.id">
                            <span class="material-icons">create</span>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
                <div v-else>
                  <p>Noch keine Fahrzeug Kategorien verfügbar.</p>
                </div>


              </div>

            </div>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      table: {
        columns: [
          {
            label: 'Name',
            field: 'name',
          }
        ],
      },
      vehicle_categories: [],
      search_phrase: "",
      search_loading: false,
      loading: true
    }
  },
  methods: {
    get_vehicle_categories() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle_categories = response.data.vehicle_categories;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
      })

    },
    on_row_click(params) {
      console.log(params.row.id);
      this.$router.push('/admin/vehicle_categories/'+params.row.id);
    },
    search_handler() {
      if (this.search_loading == false) {
        this.search_loading = true;
        axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories?search_phrase='+this.search_phrase, { headers: { Authorization: this.$store.getters.getToken } })
        .then(response => {
          this.vehicle_categories = response.data.vehicle_categories;
          this.search_loading = false;
        })
        .catch(error => {
          console.log(error);
        })
      }
    }
  },
  mounted () {
    this.get_vehicle_categories();
  }
}
</script>

<style lang="scss">

</style>
